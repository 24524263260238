import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from './shared-module/Services/utils/utils.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Chargebizz-Adminpanel';
  loading: Boolean = false;
  loadingSubscription: Subscription;
  constructor(
    private router: Router,
    private utils: UtilsService,
    private cdRef: ChangeDetectorRef
  ) {}
  ngOnInit() {}
}
